const Admin_Panel = 'info@amazonkindlewriters.com';
const PHONE = '(855) 216-6440';
const PHONE_HREF = 'tel:8552166440';
const EMAIL = 'info@amazonkindlewriters.com';
const EMAIL_HREF = 'mailto:info@amazonkindlewriters.com';
const CONTACT_EMAIL = 'info@amazonkindlewriters.com';
const CONTACT_EMAIL_HREF = 'mailto:info@amazonkindlewriters.com';
const ADDRESS = '22 N San Joaquin St, Stockton, CA 95202, USA';
const WEBSITE_NAME = 'Amazon Kindle Writers';
const SITE_CURRENCY_SYMBOLS = '$';
const WEBSITE_LOGO =
  '';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
  WEBSITE_LOGO,
};
