import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import {
  Loader,
} from '../../assets/images';

export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "creativeghostwriters.org"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=660861';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://amazonkindlewriters.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <div className="">
      <Modal
        show={open} onHide={onCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div class="popupform-main">
          <div class="pop-form ta-center">
            <div id="pop-form" class="col-sm-offset-12 col-sm-12 col-lg-7">
              <h3>Discuss Your Project Today! </h3>
              <h2>Avail Exclusive Discounts</h2>
              <h4>On Your Upcoming Project </h4>
              <a href="javascript:;" class="close-btn ta-center" onClick={onCloseModal}>X</a>
              <div class="clearfix"></div>
              <form class="form_submission main-pop" method="POST" id="popup_js" onSubmit={onSubmitFunc}>
                <div class="col-md-12">
                  <div class="control-group">
                    <input type="text" class="form-control" placeholder="Name" name="name" required="" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="control-group">
                    <input type="email" class="form-control" placeholder="Enter your email here" name="email" required="" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                  </div>
                </div>
                <div class="col-md-12 phonecode">
                  <div class="control-group clearfix">
                    <div class="numberarea">
                      <input type="text" class="form-control number" minlength="10" maxlength="12" placeholder="Phone Number" name="phone" required="" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                    </div>
                  </div>
                </div>
                <div class="col-md-12 ">
                  <div class="control-group clearfix">
                    <div class="textarea_auto">
                      <textarea class="form-control" name="message" placeholder="Talk about your project" onChange={(e) => setFormData({ ...formData, message: e.target.value })}></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="control-group clearfix  ">
                    {loading == true ? (
                      <div className="loader">
                        <img alt="loader" src={Loader} />
                      </div>
                    ) : (
                      <input type="submit" class="btn btn-default blue_btn pop_btn submit-btn fspx-23  ls-medium d-block w-100 fw-bold" value="Send Now" />
                    )
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div >
  );
};
