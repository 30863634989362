import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import {
   aboutimg,
   banner,
   bannerbook1,
   bannerbook2,
   bannerlogo,
   clientimg1,
   clientimg2,
   clientimg3,
   clientimg4,
   clientimg5,
   clientimg6,
   cta,
   ctaimg,
   ctaleftimg,
   ctarightimg,
   footerform,
   logo,
   payments,
   popup,
   porticon,
   portfolio,
   serviceicon1,
   serviceicon2,
   serviceicon3,
   serviceicon4,
   serviceicon5,
   serviceicon6,
   services,
   servicesimg,
   stars,
   testibook1,
   trustpilot,
   alltabimg1,
   alltabimg2,
   alltabimg3,
   alltabimg4,
   alltabimg5,
   alltabimg6,
   alltabimg7,
   alltabimg8,
   alltabimg9,
   alltabimg10,
   alltabimg11,
   alltabimg12,
   bioimg1,
   bioimg2,
   bioimg3,
   bioimg4,
   bioimg5,
   bioimg6,
   bioimg7,
   bioimg8,
   bioimg9,
   bioimg10,
   bioimg11,
   bioimg12,
   childbookimg1,
   childbookimg2,
   childbookimg3,
   childbookimg4,
   childbookimg5,
   childbookimg6,
   childbookimg7,
   childbookimg8,
   childbookimg9,
   childbookimg10,
   childbookimg11,
   childbookimg12,
   fictionimg1,
   fictionimg2,
   fictionimg3,
   fictionimg4,
   fictionimg5,
   fictionimg6,
   fictionimg7,
   fictionimg8,
   fictionimg9,
   fictionimg10,
   fictionimg11,
   fictionimg12,
   informativeimg1,
   informativeimg2,
   informativeimg3,
   informativeimg4,
   informativeimg5,
   informativeimg6,
   informativeimg7,
   informativeimg8,
   informativeimg9,
   informativeimg10,
   informativeimg11,
   informativeimg12,
   memoirimg1,
   memoirimg2,
   memoirimg3,
   memoirimg4,
   memoirimg5,
   memoirimg6,
   memoirimg7,
   memoirimg8,
   memoirimg9,
   memoirimg10,
   memoirimg11,
   memoirimg12,
   nonbioimg1,
   nonbioimg2,
   nonbioimg3,
   nonbioimg4,
   nonbioimg5,
   nonbioimg6,
   nonbioimg7,
   nonbioimg8,
   nonbioimg9,
   nonbioimg10,
   nonbioimg11,
   nonbioimg12,
   fiction1,
   fiction2,
   fiction3,
   fiction4,
} from '../../assets/images';
import { WEBSITE_NAME } from '../../config';

export default function TestimonialSlider() {
   var settings = {
      loop: true,
      margin: 0,
      dots: false,
      center: true,
      nav: true,
      // navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
      responsive:{
         0:{
               items: 1,
               dots: true,
               nav: false
         },
         768:{
               items: 1,
         },
         992:{
               items:1
         }
      }
   };
   return (
      <OwlCarousel class="testimonial_slider owl-carousel owl-theme" {...settings}>
         <div class="item">
            <div class="row align-items-center">
               <div class="col-lg-3 col-md-4">
                  <img title="image" src={testibook1} alt="" class="testi_book" />
               </div>
               <div class="col-lg-9 col-md-8">
                  <h3>Jassica W. - Author</h3>
                  <p>I wanted the world to learn from my struggles of being a mother to a down's syndrome child and {WEBSITE_NAME} did a wonderful job by writing this book for me. I will definitely recommend them to others who need their services.</p>
                  <img title="image" src={stars} alt="" class="stars" />
                  <img title="image" src={trustpilot} alt="" class="trustpilot" />
               </div>

            </div>
         </div>
         <div class="item">
            <div class="row align-items-center">
               <div class="col-lg-3 col-md-4">
                  <img title="image" src={testibook1} alt="" class="testi_book" />
               </div>
               <div class="col-lg-9 col-md-8">
                  <h3>Martha Doyle</h3>
                  <p>{WEBSITE_NAME} made my dream of becoming an author come true. They are some crazy creative people with a lot of ideas and took care of everything from outlining the plot to designing and publishing. They revised the draft times and again to my satisfaction.</p>
                  <img title="image" src={stars} alt="" class="stars" />
                  <img title="image" src={trustpilot} alt="" class="trustpilot" />
               </div>

            </div>
         </div>
         <div class="item">
            <div class="row align-items-center">
               <div class="col-lg-3 col-md-4">
                  <img title="image" src={testibook1} alt="" class="testi_book" />
               </div>
               <div class="col-lg-9 col-md-8">
                  <h3>Evan Bruce</h3>
                  <p>I was referred to {WEBSITE_NAME} by a friend – and I couldn't thank him enough for this. The professionals at {WEBSITE_NAME} took time to understand my requirements and took it to their heart to convert my book idea into a published reality.</p>
                  <img title="image" src={stars} alt="" class="stars" />
                  <img title="image" src={trustpilot} alt="" class="trustpilot" />
               </div>

            </div>
         </div>
      </OwlCarousel>
   );
}


