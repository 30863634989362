import React, { useState } from 'react';
import axios from 'axios';

const Form = () => {
   const [step, setStep] = useState(1);
   const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
      phone: '',
   });

   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState(false);

   const nextPrev = (n) => {
      if (n === 1) {
         // Moving forward
         if (step === 1 && formData.name === '') {
            setStep(2);
         } else if (step === 2 && formData.email === '') {
            setStep(3);
         } else if (step === 3 && formData.phone === '') {
            //    setStep(4);
            // } else if (step === 4 && formData.message !== '') {
            //    submitForm();
         } else if (step < 3) {
            setStep(step + n);
         }
      } else {
         // Moving backward
         if (step > 1) {
            setStep(step + n);
         }
      }
   };

   const submitForm = async (e) => {
      e.preventDefault();
      // No event parameter here, so we don't call preventDefault
      console.log('formData', formData);
      if (formData.phone.length < 9) {
         setErrors(true);
      } else {
         const currentUrl = window.location.href;
         const protocol = window.location.protocol;
         const hostname = window.location.hostname;

         const baseUrl = `${protocol}//${hostname}`;
         const queryStringFormData = new URLSearchParams(formData).toString();

         let finalReq = {
            ...formData,
            source: `${currentUrl}${queryStringFormData}`,
            domain: baseUrl,
            lead_url: currentUrl,
            url: `${currentUrl}${queryStringFormData}`,
         };
         try {
            setLoading(true);
            const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=660861';
            const config = {
               headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
               },
            };

            const formDataString = new URLSearchParams(finalReq).toString();

            const response = await axios.post(apiUrl, formDataString, config);
            if (response.status === 200) {
               const queryString = new URLSearchParams(response.data.data).toString();
               setLoading(false);
               setErrors(false);
               setFormData({
                  name: '',
                  email: '',
                  message: '',
                  phone: '',
               });
               window.location.href = `https://amazonkindlewriters.com/thankyou?${queryString}`;
            }
            console.log('response', response);
         } catch (error) {
            console.error('Error:', error);
            setLoading(false);
         }
      }
   };



   return (
      <div class="banner_form_area">
         <form class="form_submission" id="regForm" method="POST" onSubmit={submitForm}>
            <div class="form_wrap">
               <div class="input_fields">
                  <div class="form-group side-form-group tab tab-1">
                     {step === 1 && (
                        <input
                           type="text"
                           class="form-control"
                           name="name"
                           placeholder="Enter Your Name"
                           value={formData.name}
                           onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                     )}
                  </div>
                  <div class="form-group side-form-group tab tab-2">
                     {step === 2 && (
                        <input
                           type="email"
                           class="form-control"
                           name="email"
                           placeholder="Enter Your E-mail"
                           value={formData.email}
                           onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                     )}
                  </div>
                  <div class="form-group side-form-group tab tab-3">
                     {step === 3 && (
                        <input
                           type="number"
                           pattern="\d{12}"
                           class="form-control"
                           name="phone"
                           placeholder="Phone no"
                           value={formData.phone}
                           onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        />
                     )}
                  </div>
               </div>
               <div class="banner_form_btn">
                  {step > 1 && (
                     <button class="tab-1-btn sbt_set next" type="button" onClick={() => nextPrev(-1)}>
                        Previous
                     </button>
                  )}
                  {step < 3 && (
                     <button class="tab-1-btn sbt_set next" type="button" onClick={() => nextPrev(1)}>
                        Next
                     </button>
                  )}
                  {step === 3 && (
                     <button type="submit" class="tab-1-btn sbt_set next">
                        Submit
                     </button>
                  )}
               </div>
            </div>
         </form>

      </div>
   );
}

export default Form;