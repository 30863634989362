import React,{useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import { ModalSection } from '../../Components/modalSection';
import {
   aboutimg,
   banner,
   bannerbook1,
   bannerbook2,
   bannerlogo,
   clientimg1,
   clientimg2,
   clientimg3,
   clientimg4,
   clientimg5,
   clientimg6,
   cta,
   ctaimg,
   ctaleftimg,
   ctarightimg,
   footerform,
   logo,
   payments,
   popup,
   porticon,
   portfolio,
   serviceicon1,
   serviceicon2,
   serviceicon3,
   serviceicon4,
   serviceicon5,
   serviceicon6,
   services,
   servicesimg,
   stars,
   testibook1,
   trustpilot,
   alltabimg1,
   alltabimg2,
   alltabimg3,
   alltabimg4,
   alltabimg5,
   alltabimg6,
   alltabimg7,
   alltabimg8,
   alltabimg9,
   alltabimg10,
   alltabimg11,
   alltabimg12,
   bioimg1,
   bioimg2,
   bioimg3,
   bioimg4,
   bioimg5,
   bioimg6,
   bioimg7,
   bioimg8,
   bioimg9,
   bioimg10,
   bioimg11,
   bioimg12,
   childbookimg1,
   childbookimg2,
   childbookimg3,
   childbookimg4,
   childbookimg5,
   childbookimg6,
   childbookimg7,
   childbookimg8,
   childbookimg9,
   childbookimg10,
   childbookimg11,
   childbookimg12,
   fictionimg1,
   fictionimg2,
   fictionimg3,
   fictionimg4,
   fictionimg5,
   fictionimg6,
   fictionimg7,
   fictionimg8,
   fictionimg9,
   fictionimg10,
   fictionimg11,
   fictionimg12,
   informativeimg1,
   informativeimg2,
   informativeimg3,
   informativeimg4,
   informativeimg5,
   informativeimg6,
   informativeimg7,
   informativeimg8,
   informativeimg9,
   informativeimg10,
   informativeimg11,
   informativeimg12,
   memoirimg1,
   memoirimg2,
   memoirimg3,
   memoirimg4,
   memoirimg5,
   memoirimg6,
   memoirimg7,
   memoirimg8,
   memoirimg9,
   memoirimg10,
   memoirimg11,
   memoirimg12,
   nonbioimg1,
   nonbioimg2,
   nonbioimg3,
   nonbioimg4,
   nonbioimg5,
   nonbioimg6,
   nonbioimg7,
   nonbioimg8,
   nonbioimg9,
   nonbioimg10,
   nonbioimg11,
   nonbioimg12,
   fiction1,
   fiction2,
   fiction3,
   fiction4,
} from '../../assets/images';

export default function ServicesSlider({onOpenModal}) {

   var settings = {
      loop: true,
      margin: 0,
      dots: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      responsive: {
         0: {
            items: 1,
         },
         576: {
            items: 2,
         },
         768: {
            items: 3,
         },
         992: {
            items: 4
         }
      }
   };
   return (
      <OwlCarousel class="services_slider owl-carousel owl-theme" {...settings}>
         <div class="item">
            <div class="service_box">
               <img title="image" src={serviceicon1} alt="" />
               <h3>Story Writing</h3>
               <p>Have a fantastic story idea that you cannot find time to write? Our professional ghost story
                  writing services are the answer.</p>
               <a href="Javascript:" onClick={onOpenModal}>Read More</a>
            </div>
         </div>
         <div class="item">
            <div class="service_box">
               <img title="image" src={serviceicon2} alt="" />
               <h3>Non Fiction Writing</h3>
               <p>Have a book to write? Don’t know how to do that? Take our hand and let us rid you of the burdens of
                  how.</p>
               <a href="Javascript:" onClick={onOpenModal}>Read More</a>
            </div>
         </div>
         <div class="item">
            <div class="service_box">
               <img title="image" src={serviceicon3} alt="" />
               <h3>Rhymes Writing</h3>
               <p>Let us create an exhilarating experience for you by providing you with our impeccable rhyme writing
                  services.</p>
               <a href="Javascript:" onClick={onOpenModal}>Read More</a>
            </div>
         </div>
         <div class="item">
            <div class="service_box">
               <img title="image" src={serviceicon4} alt="" />
               <h3>Ebook Writing</h3>
               <p>Do you need a reader magnet to offer to your clients and customers? Let us handle the task for you.
               </p>
               <a href="Javascript:" onClick={onOpenModal}>Read More</a>
            </div>
         </div>
         <div class="item">
            <div class="service_box">
               <img title="image" src={serviceicon5} alt="" />
               <h3>Biography Writing</h3>
               <p>Do you have a success story that you think might benefit others? Hire a writer for your biography
                  now and get it published!</p>
               <a href="Javascript:" onClick={onOpenModal}>Read More</a>
            </div>
         </div>
         <div class="item">
            <div class="service_box">
               <img title="image" src={serviceicon6} alt="" />
               <h3>Autobiography</h3>
               <p>Get a high-quality autobiography written at the most reasonable rates today!</p>
               <a href="Javascript:" onClick={onOpenModal}>Read More</a>
            </div>
         </div>
      </OwlCarousel>

   );
}
