import React, { useRef, useEffect } from 'react';

import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

function Fancybox({ options = {}, delegate = '[data-fancybox]', children }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    // Bind Fancybox to the container with the provided delegate and options
    NativeFancybox.bind(container, delegate, options);

    // Cleanup function to unbind and close Fancybox when the component unmounts
    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  }, [delegate, options]); // Re-run this effect if delegate or options change

  return <div ref={containerRef}>{children}</div>;
}

export default Fancybox;
