import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
  Loader,
  aboutimg,
  banner,
  bannerbook1,
  bannerbook2,
  bannerlogo,
  clientimg1,
  clientimg2,
  clientimg3,
  clientimg4,
  clientimg5,
  clientimg6,
  cta,
  ctaimg,
  ctaleftimg,
  ctarightimg,
  footerform,
  logo,
  payments,
  popup,
  porticon,
  portfolio,
  serviceicon1,
  serviceicon2,
  serviceicon3,
  serviceicon4,
  serviceicon5,
  serviceicon6,
  services,
  servicesimg,
  stars,
  testibook1,
  trustpilot,
  alltabimg1,
  alltabimg2,
  alltabimg3,
  alltabimg4,
  alltabimg5,
  alltabimg6,
  alltabimg7,
  alltabimg8,
  alltabimg9,
  alltabimg10,
  alltabimg11,
  alltabimg12,
  bioimg1,
  bioimg2,
  bioimg3,
  bioimg4,
  bioimg5,
  bioimg6,
  bioimg7,
  bioimg8,
  bioimg9,
  bioimg10,
  bioimg11,
  bioimg12,
  childbookimg1,
  childbookimg2,
  childbookimg3,
  childbookimg4,
  childbookimg5,
  childbookimg6,
  childbookimg7,
  childbookimg8,
  childbookimg9,
  childbookimg10,
  childbookimg11,
  childbookimg12,
  fictionimg1,
  fictionimg2,
  fictionimg3,
  fictionimg4,
  fictionimg5,
  fictionimg6,
  fictionimg7,
  fictionimg8,
  fictionimg9,
  fictionimg10,
  fictionimg11,
  fictionimg12,
  informativeimg1,
  informativeimg2,
  informativeimg3,
  informativeimg4,
  informativeimg5,
  informativeimg6,
  informativeimg7,
  informativeimg8,
  informativeimg9,
  informativeimg10,
  informativeimg11,
  informativeimg12,
  memoirimg1,
  memoirimg2,
  memoirimg3,
  memoirimg4,
  memoirimg5,
  memoirimg6,
  memoirimg7,
  memoirimg8,
  memoirimg9,
  memoirimg10,
  memoirimg11,
  memoirimg12,
  nonbioimg1,
  nonbioimg2,
  nonbioimg3,
  nonbioimg4,
  nonbioimg5,
  nonbioimg6,
  nonbioimg7,
  nonbioimg8,
  nonbioimg9,
  nonbioimg10,
  nonbioimg11,
  nonbioimg12,
  fiction1,
  fiction2,
  fiction3,
  fiction4,
} from './assets/images';
import ServicesSlider from './Components/ServicesSlider';
import { ModalSection } from './Components/modalSection';
import TestimonialSlider from './Components/TestimonialSlider';
import Form from './Components/Form';
import Fancybox from './Components/Fancybox'
import { WEBSITE_NAME } from './config';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 4000,
    });
  }, []);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    customers_meta: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "creativeghostwriters.org"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=660861';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            customers_meta: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://amazonkindlewriters.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const toggleChat = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.toggle();
      }
    };

    const chatButtons = document.querySelectorAll('.chatt'); // Select all elements with the class 'chatt'

    chatButtons.forEach(chatButton => {
      chatButton.addEventListener('click', toggleChat);
    });

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      chatButtons.forEach(chatButton => {
        chatButton.removeEventListener('click', toggleChat);
      });
    };
  }, []);
  return (
    <div className="App">

      <header>
        <div class="container" data-aos="fade-down" data-aos-duration="1500">
          <div class="header_wrap">
            <div class="logo_area">
              <img title="image" src={logo} alt="" class="img-fluid" />
            </div>
            <ul>
              <li><a href="javascript:;" class="header_btn trns_btn hvr-radial-out popUpBtn" onClick={onOpenModal}>Get A Qoute</a></li>
              <li><a href="javascript:;" class="header_btn white_btn hvr-radial-out chatt" >Live Chat</a></li>
            </ul>
          </div>
        </div>
      </header>

      <section class="banner">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-8" data-aos="fade-up" data-aos-duration="1500">
              <h1>The Most <strong>Trusted & Reliable Ghostwriters</strong> In All Of US</h1>
              <p>Your audience is anticipating your next great work! You can become a best-selling author with the assistance of our skilled editors, proofreaders, and ghostwriters.</p>

              <Form />
              <img title="image" src={bannerlogo} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
        <img title="image" src={bannerbook1} class="bnr_img bannerbook1" alt="" data-aos="fade-up-right" data-aos-duration="1500" />
        <img title="image" src={bannerbook2} class="bnr_img bannerbook2" alt="" data-aos="fade-up-left" data-aos-duration="1500" />
      </section>

      <section class="about ">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7" data-aos="fade-up-right" data-aos-duration="1500">
              <div class="title_block dark_hd">
                <h2>We'll Write You <br />Your Next <strong>Best-Seller!</strong></h2>
                <p>At {WEBSITE_NAME}, we meticulously write and publish your book using the words and concepts you provide. Our staff of highly skilled ghostwriters has produced thousands of profitable books that have been carefully written, edited, formatted, and published. They are experts in a variety of genres. The majority of our books have been widely acclaimed and are among the best-selling authors.</p>
                <a href="javascript:;" class="def_btn hvr-radial-out blue_btn popUpBtn" onClick={onOpenModal}>Get A Qoute</a>
                <a href="javascript:;" class="def_btn hvr-radial-out grey_btn btn_space chatt" >Start Live Chat</a>
              </div>
            </div>
            <div class="col-lg-5 resp_order" data-aos="fade-up-left" data-aos-duration="1500">
              <img title="image" src={aboutimg} alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section class="portfolio_sec sec_pt sec_pb">
        <div class="container">
          <div class="sec_title text-center light_hd">
            <h2 data-aos="fade-up" data-aos-duration="1500"><strong>Our Expert Services</strong></h2>
          </div>
          <div class="portfolio_tabs">
            <nav>
              <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active" id="nav1-tab" data-toggle="tab" href="#nav1" role="tab" aria-controls="nav1" aria-selected="true" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1500">Fiction</a>
                <a class="nav-item nav-link" id="nav2-tab" data-toggle="tab" href="#nav2" role="tab" aria-controls="nav2" aria-selected="true" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1500">Biography</a>
                <a class="nav-item nav-link" id="nav3-tab" data-toggle="tab" href="#nav3" role="tab" aria-controls="nav3" aria-selected="true" data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1500">Non-Fiction</a>
                <a class="nav-item nav-link" id="nav4-tab" data-toggle="tab" href="#nav4" role="tab" aria-controls="nav4" aria-selected="true" data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1500">Informative</a>
                <a class="nav-item nav-link" id="nav5-tab" data-toggle="tab" href="#nav5" role="tab" aria-controls="nav5" aria-selected="true" data-aos="zoom-in" data-aos-delay="500" data-aos-duration="1500">Memoir</a>
                <a class="nav-item nav-link" id="nav6-tab" data-toggle="tab" href="#nav6" role="tab" aria-controls="nav6" aria-selected="true" data-aos="zoom-in" data-aos-delay="600" data-aos-duration="1500">Children's Books</a>
              </div>
            </nav>
            <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav1" role="tabpanel" aria-labelledby="nav1-tab">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: true,
                    },
                  }}
                >
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500">
                      <a href={fictionimg1} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={fictionimg1} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
                      <a href={fictionimg2} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={fictionimg2} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
                      <a href={fictionimg3} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={fictionimg3} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1500">
                      <a href={fictionimg4} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={fictionimg4} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                  </div>
                </Fancybox>
              </div>
              <div class="tab-pane fade" id="nav2" role="tabpanel" aria-labelledby="nav2-tab">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: true,
                    },
                  }}
                >
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={bioimg1} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={bioimg1} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={bioimg2} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={bioimg2} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={bioimg3} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={bioimg3} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={bioimg4} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={bioimg4} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                  </div>
                </Fancybox>
              </div>
              <div class="tab-pane fade" id="nav3" role="tabpanel" aria-labelledby="nav3-tab">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: true,
                    },
                  }}
                >
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={nonbioimg1} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={nonbioimg1} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={nonbioimg2} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={nonbioimg2} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={nonbioimg3} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={nonbioimg3} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={nonbioimg4} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={nonbioimg4} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                  </div>
                </Fancybox>
              </div>
              <div class="tab-pane fade" id="nav4" role="tabpanel" aria-labelledby="nav4-tab">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: true,
                    },
                  }}
                >
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={informativeimg1} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={informativeimg1} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={informativeimg2} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={informativeimg2} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={informativeimg3} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={informativeimg3} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={informativeimg4} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={informativeimg4} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                  </div>
                </Fancybox>
              </div>
              <div class="tab-pane fade" id="nav5" role="tabpanel" aria-labelledby="nav5-tab">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: true,
                    },
                  }}
                >
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={memoirimg1} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={memoirimg1} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={memoirimg2} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={memoirimg2} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={memoirimg3} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={memoirimg3} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={memoirimg4} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={memoirimg4} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                  </div>
                </Fancybox>
              </div>
              <div class="tab-pane fade" id="nav6" role="tabpanel" aria-labelledby="nav6-tab">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: true,
                    },
                  }}
                >
                  <div class="row">
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={childbookimg1} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={childbookimg1} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={childbookimg2} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={childbookimg2} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={childbookimg3} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={childbookimg3} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                    <div class="col-md-3 col-sm-6 col-6 mb-4">
                      <a href={childbookimg4} class="portfolio-box" data-fancybox="port" data-aos="zoom-in" data-aos-duration="1000">
                        <img title="image" src={childbookimg4} />
                        <img title="image" src={porticon} alt="" />
                      </a>
                    </div>
                  </div>
                </Fancybox>
              </div>
            </div>
            <div class="sec_btn text-center" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1500">
              <a href="javascript:;" class="def_btn hvr-radial-out grey_btn popUpBtn" onClick={onOpenModal}>Let’s Get Started</a>
              <a href="javascript:;" class="def_btn hvr-radial-out white_btn btn_space popUpBtn" onClick={onOpenModal}>Request Free Consultation</a>
            </div>
          </div>
        </div>
      </section>

      <section class="cta">
        <div class="container-fluid">
          <div class="title_block dark_hd text-center" data-aos="fade-down" data-aos-duration="1500">
            <h2>Interested In <strong>Writing, Editing, Proofreading, Or Publishing Your Book?</strong> <br />Get Every Solution You Need Under One Roof.</h2>
            <div class="text-center">
              <a href="javascript:;" class="def_btn hvr-radial-out blue_btn popUpBtn" onClick={onOpenModal}>Get A Qoute</a>
              <a href="javascript:;" class="def_btn hvr-radial-out white_btn btn_space chatt" >Start Live Chat</a>
            </div>
          </div>
        </div>
        <img title="image" src={ctaleftimg} alt="" class="cta_left_img" />
        <img title="image" src={ctarightimg} alt="" class="cta_right_img" />
      </section>

      <section class="services sec_pb sec_pt">
        <div class="container-fluid">
          <div class="sec_title" data-aos="fade-up" data-aos-duration="1500">
            <h2><strong>Our Expert Services</strong></h2>
            <p>Get to know more about our personalized and unparalleled services.</p>
          </div>
          <ServicesSlider onOpenModal={onOpenModal} />
        </div>
        <img title="image" src={servicesimg} alt="" class="services_img" />
      </section>

      <section class="testimonial sec_pb sec_pt">
        <div class="container">
          <div class="sec_title text-center">
            <h2><strong>What Our Clients Say</strong></h2>
            <p>We've helped hundreds of people to capture their stories and we have a lot of happy <br />clients! Learn about their experience in their own words.</p>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-10">
              <TestimonialSlider />
            </div>
          </div>
        </div>
        <img title="image" src={clientimg1} class="client_img1" />
        <img title="image" src={clientimg2} class="client_img2" />
        <img title="image" src={clientimg3} class="client_img3" />
        <img title="image" src={clientimg4} class="client_img4" />
        <img title="image" src={clientimg5} class="client_img5" />
        <img title="image" src={clientimg6} class="client_img6" />
      </section>

      <section class="footer_form sec_pt sec_pb">
        <div class="container">
          <div class="sec_title light_hd text-center">
            <h2><strong>Grab onto Our Expertise</strong></h2>
            <p>Fill in your details and grab this oppurtunity to be a part of our family.</p>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <form class="form_submission" method="POST" onSubmit={onSubmitFunc}>
                <div class="contact-form">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="contact-form-control">
                        <input type="text" class="form-control" placeholder="Your Name" name="name" required onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="contact-form-control">
                        <input type="email" class="form-control" placeholder="Your Email" name="email" required onChange={(e) => setFormData({ ...formData, email: e.target.value, })} />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="contact-form-control">
                        <input type="number" minlength="10" maxlength="12" pattern="\d{12}" class="form-control" placeholder="Phone Number" name="phone" required onChange={(e) => setFormData({ ...formData, phone: e.target.value, })} />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="contact-form-control">
                        <input type="text" class="form-control" placeholder="Subject" name="customers_meta" required onChange={(e) => setFormData({ ...formData, customers_meta: e.target.value, })} />
                      </div>
                    </div>
                    <div class="col-md-12">
                      {loading == true ? (
                        <div className="loader">
                          <img alt="loader" src={Loader} />
                        </div>
                      ) : (
                        <button class="purchase-btn btn-10" type="submit" > Submit</button>
                      )
                      }
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <div class="copyright">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <p>Copyright © {WEBSITE_NAME} All Rights Reserved 2024</p>
            </div>
            <div class="col-lg-6 text-right">
              <img title="image" src={payments} alt="" />
            </div>
          </div>
        </div>
      </div>

      <ModalSection open={open} onCloseModal={onCloseModal} />
    </div>
  );
}

export default App;
